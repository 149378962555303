<template>
  <div class="row m-1">
    <div class="col-xl-6">
      <div class="card card-psh border">
        <div class="bg-light-primary rounded-top py-2 px-1">
          <div class="psh-header mb-0 d-flex align-items-center">
            <div class="mr-1 border-0">
              <p-icon name="bi-gear-fill" size="48px" color="primary" />
            </div>
            <div class="d-flex flex-column">
              <h4 class="card-title mb-25">
                Storage Fee Setup
              </h4>
              <p class="card-text mb-0">
                Configure Settings
              </p>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-12 mb-2">
              These values set the storage charge schedule for client items
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="form-group col-xl-8 col-md-12">
                  <label for="text-account-no">Daily Storage Fee Per Cubic Feet $</label>
                  <dx-util-number-box
                    v-model="dailyStorageFeePerqf.value"
                    format="$ #,##0.##"
                    styling-mode="filled"
                  />
                </div>
                <div class="form-group col-xl-4 col-md-6">
                  <label for="text-account-no" />
                    <dx-util-button
                      icon="save"
                      text="Save"
                      :hint="`Save ${managedKeys.DAILYSTORAGEFEEPERQF.text}`"
                      type="default"
                      styling-mode="contained"
                      :element-attr="btnElementAttr"
                      @click="createOrUpdateDailyStorageFeePerqf"
                    />
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row">
                <div class="form-group col-xl-8 col-md-12">
                  <label for="text-account-no">Free Storage Day Count</label>
                  <dx-util-number-box
                    v-model="freeStoragedayCount.value"
                    styling-mode="filled"
                  />
                </div>
                <div class="form-group col-xl-4 col-md-6">
                  <label for="text-account-no" />
                    <dx-util-button
                      icon="save"
                      text="Save"
                      :hint="`Save ${managedKeys.FREESTORAGEDAYCOUNT.text}`"
                      type="default"
                      styling-mode="contained"
                      :element-attr="btnElementAttr"
                      @click="createOrUpdateFreeStoragedayCount"
                    />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import tenantService from '@/http/requests/tenant/tenan.settings'
import managedKeysEnum from '@/enums/managedKeysEnum'
import valueTypeEnum from '@/enums/valueTypeEnum'
import scopeTypeEnum from '@/enums/scopeTypeEnum'

export default {
  data() {
    return {
      managedKeys: managedKeysEnum,
      freeStoragedayCount: {
        id: null,
        key: managedKeysEnum.FREESTORAGEDAYCOUNT.key,
        value: 0,
        tenantId: null,
        scopeType: scopeTypeEnum.TENANTSCOPEBACKEND.key,
        valueType: valueTypeEnum.INTEGER.key,
        version: null,
        referenceId: null,
      },
      dailyStorageFeePerqf: {
        id: null,
        key: managedKeysEnum.DAILYSTORAGEFEEPERQF.key,
        value: 0,
        tenantId: null,
        scopeType: scopeTypeEnum.TENANTSCOPEBACKEND.key,
        valueType: valueTypeEnum.BIGDECIMAL.key,
        version: null,
        referenceId: null,
      },
      btnElementAttr: {
        class: 'btn-block',
      },
    }
  },
  mounted() {
    this.getCurrentFreeStoragedayCount()
    this.getCurrentDailyStorageFeePerqf()
  },
  methods: {
    // #region Shipping Up Charge Min Dollar
    getCurrentFreeStoragedayCount() {
      tenantService.fetchByKey(managedKeysEnum.FREESTORAGEDAYCOUNT.key).then(result => {
        const data = { ...result.data }
        if (data.id) {
          this.freeStoragedayCount = {
            id: data.id,
            key: data.key,
            value: parseFloat(data.value),
            tenantId: data.tenantId,
            scopeType: scopeTypeEnum.TENANTSCOPEBACKEND.key,
            valueType: data.valueType,
            version: data.version,
            referenceId: data.referenceId,
          }
        }
      })
    },
    createOrUpdateFreeStoragedayCount() {
      const freeStoragedayCount = { ...this.freeStoragedayCount }
      if (freeStoragedayCount.id) {
        tenantService
          .update(freeStoragedayCount)
          .then(result => {
            const { id, version, tenantId } = result.data.body
            this.freeStoragedayCount = {
              ...this.freeStoragedayCount,
              id,
              version,
              tenantId,
            }
          })
      } else {
        tenantService
          .create(freeStoragedayCount)
          .then(result => {
            const { id, version, tenantId } = result.data.body
            this.freeStoragedayCount = {
              ...this.freeStoragedayCount,
              id,
              version,
              tenantId,
            }
          })
      }
    },
    // #endregion
    getCurrentDailyStorageFeePerqf() {
      tenantService.fetchByKey(managedKeysEnum.DAILYSTORAGEFEEPERQF.key).then(result => {
        const data = { ...result.data }
        if (data.id) {
          this.dailyStorageFeePerqf = {
            id: data.id,
            key: data.key,
            value: parseFloat(data.value),
            tenantId: data.tenantId,
            scopeType: scopeTypeEnum.TENANTSCOPEBACKEND.key,
            valueType: data.valueType,
            version: data.version,
            referenceId: data.referenceId,
          }
        }
      })
    },
    createOrUpdateDailyStorageFeePerqf() {
      const dailyStorageFeePerqf = { ...this.dailyStorageFeePerqf }
      if (dailyStorageFeePerqf.id) {
        tenantService
          .update(dailyStorageFeePerqf)
          .then(result => {
            const { id, version, tenantId } = result.data.body
            this.dailyStorageFeePerqf = {
              ...this.dailyStorageFeePerqf,
              id,
              version,
              tenantId,
            }
          })
      } else {
        tenantService
          .create(dailyStorageFeePerqf)
          .then(result => {
            const { id, version, tenantId } = result.data.body
            this.dailyStorageFeePerqf = {
              ...this.dailyStorageFeePerqf,
              id,
              version,
              tenantId,
            }
          })
      }
    },
  },
}
</script>

<style>

</style>
